import { css } from 'styled-components'
import Media from './media'
import { spacing } from './theme'

export const hideAtMobile = css`
  ${Media.phone`
    display: none;
  `}
`
export const showAtMobile = css`
  display: none;
  ${Media.phone`
    display: block;
  `}
`
export const textEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const fullWidthAtMobile = css`
  @media (max-width: 768px) {
    width: auto;
    margin: 0 ${spacing.base};
  }
`
export const multiLineTextEllipsis = (line) => {
  return css`
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: ${line};
    -webkit-box-orient: vertical;
  `
}
export const gradient = (color1, color2, axis = '45deg') => {
  return `linear-gradient(${axis}, ${color1}, ${color2});`
}
export function placeholder(color) {
  return css`
    ::-moz-placeholder {
      color: ${color};
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: ${color};
    }
    ::-webkit-input-placeholder {
      color: ${color};
    }
  `
}
export const hideScrollbar = css`
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
`
