export const color = {
  primaryDark: '#02A6BC',
  primary: '#2DBEC3',
  primaryLight: '#2BBFA5',
  yellow: '#F2C410',
  textDark: '#333',
  text: '#fff',
  textLight: '#999',
  panel: 'rgb(51 51 51 / 60%)',
  panelLight: 'rgb(36 52 58 / 60%)',
}
export const spacing = {
  small: '10px',
  base: '20px',
  large: '30px',
  plus: '40px',
  super: '50px',
}
export const shape = {
  radiusSmall: '5px',
  radiusBase: '10px',
  radiusLarge: '10px',
}
export const fontSize = {
  tiny: '0.625rem', // NOTE: 10px on desktop
  mini: '0.75rem', // NOTE: 12px on desktop
  small: '0.875rem', // NOTE: 14px on desktop
  base: '1rem', // NOTE: 16px on desktop
  medium: '1.125rem', // NOTE: 18px on desktop
  large: '1.5rem', // NOTE: 24px on desktop
  plus: '2.25rem', //NOTE: 36px on desktop
  super: '3rem', // NOTE: 48px on desktop
  giant: '3.75rem', // NOTE: 60px on desktop
  huge: '4.5rem', // NOTE: 72px on desktop
}
