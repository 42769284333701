import { css } from 'styled-components'

const sizes = {
  laptop: 1600,
  tablet: 1024,
  phone: 812,
}

const Media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export default Media
