import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    outline: none;
  }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
  }
  html {
    overflow: hidden;
  }
  body {
    font-family: "Source Han Sans CN", "Source Han Sans SC","Hiragino Sans GB", "Bank Gothic Medium BT", sans-serif;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    color: white;
  }
  li {
    list-style: none;
  }
  div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, button, textarea, p, blockquote, th, td {
    margin: 0;
    padding: 0;
  }
  button, input, select, textarea {
    font-size:100%;
  }
  img {
    border: 0px;
  }
  .background-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
`
export default GlobalStyle
