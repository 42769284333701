import React from 'react'
import Helmet from 'react-helmet'
import { useIntl } from 'gatsby-plugin-react-intl'
import { LangType } from '../constant'

const SEO = ({
  title = '',
  keywords = '',
  description = '',
  type = 'article',
  shareImgSrc = '',
  sharePostSlug = '',
  lang = 'en',
}) => {
  const defImg =
    useIntl().locale === LangType.CN
      ? '//www.rayvision.com/seo-default-cn.jpg'
      : '//www.rayvision.com/seo-default-en.jpg'
  return (
    <Helmet title={title} htmlAttributes={{ lang: lang }}>
      <meta http-equiv='X-UA-Compatible' content='IE=edge,chrome=1' />
      <meta name='keywords' content={keywords} />
      <meta name='description' content={description} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:type' content={type} />
      <meta property='og:image' content={`https:${shareImgSrc === '' ? defImg : shareImgSrc}`} />
      <meta property='og:url' content={`https://www.rayvision.com/${sharePostSlug}`} />
      <link href='/logo.ico' rel='shortcut icon' type='image/vnd.microsoft.icon' />
    </Helmet>
  )
}
export default SEO
