import { css } from 'styled-components'
import Media from './media'
import { color, spacing } from './theme'
import transition from './transition'

const SwiperNavigationButton = css`
  .swiper-button-prev,
  .swiper-button-next {
    width: ${spacing.plus};
    height: 80px;
    background-color: ${color.textDark};
    opacity: 0.6;
    top: ${spacing.plus}%;
    transition: ${transition('background-color')};
    ::before {
      content: '';
      width: ${spacing.base};
      height: ${spacing.base};
      border-width: 0 0 2px 2px;
      border-style: solid;
      border-color: #777;
      display: block;
      transition: ${transition('border-color')};
    }
    ::after {
      content: none;
    }
    :hover {
      background-color: ${color.primaryLight};
      ::before {
        border-color: white;
      }
    }
  }
  .swiper-button-prev {
    left: ${spacing.large};
    ::before {
      transform: translateX(4px) rotate(45deg) skew(10deg, 10deg);
    }
  }
  .swiper-button-next {
    right: ${spacing.large};
    ::before {
      transform: translateX(-4px) rotate(-135deg) skew(10deg, 10deg);
    }
  }
  ${Media.phone`
    .swiper-button-prev,
    .swiper-button-next {
      width: ${spacing.base};
      height: ${spacing.plus};
      ::before {
        width: ${spacing.small};
        height: ${spacing.small};
      }
    }
    .swiper-button-prev {
      left: ${spacing.small};
    }
    .swiper-button-next {
      right: ${spacing.small};
    }
  `}
`
export default SwiperNavigationButton
